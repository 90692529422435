<template>
    <div>
        <el-input class="www200"></el-input>
        <el-button type="primary" @click="test">fdfsdsdf</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        test() {
            let that = this;
            that.axios.post("Test/ReceivePZDData", {}).then((respose) => {
                console.log(respose);
            })
        }
    }
};
</script>
<style scoped>
.www200 {
    width: 200px;
}
</style>